<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'BLOG'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.blogAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disabled="{rowData}">
                <checkbox-input v-model="rowData.disabled" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Blog" ref="blogAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddBlog @success="formSuccess"></AddBlog>
        </modal>

        <modal title="Edit Blog" ref="blogEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditBlog :initial-data="editingItem" @success="formSuccess"></EditBlog>
        </modal>

        <delete-modal ref="blogDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Blog <b v-html="deletingItem && deletingItem.title"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import AddBlog from './AddBlog';
import EditBlog from './EditBlog';
import { mapGetters } from 'vuex';

export default {
    name       : 'Blog',
    computed   : { ...mapGetters(['currentUser']) },
    components : { AddBlog, EditBlog },
    data () {
        return {
            listUrl      : urls.admin.blog.list,
            deleteUrl    : urls.admin.blog.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'title',
                    sortField  : 'title',
                    title      : 'Title',
                    titleClass : ''
                },
                {
                    name       : 'category_name',
                    sortField  : 'category_name',
                    title      : 'Category',
                    titleClass : ''
                },
                {
                    name       : 'author_name',
                    sortField  : 'author_name',
                    title      : 'Author Name',
                    titleClass : ''
                },
                {
                    name       : '__slot:disabled',
                    sortField  : 'disabled',
                    title      : 'Disabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.blogAddModal.close();
            refs.blogEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            delete this.editingItem.description;
            this.editingItem.initial_description = item.description;
            this.$refs.blogEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.blogDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Blog..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.blogDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            item.api_key = that.currentUser.api_key;
            axios.form(urls.admin.blog.addEdit, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Blog..!', 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disabled = !item.disabled;
                }
            }).catch(function (err) {
                item.disabled = !item.disabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
